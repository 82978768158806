<template>
  <div>
    <header>
      <img
        class="cadastro-logo-header tablet-hidden mobile-hidden"
        src="../assets/images/doit-logo.png"
        alt
      />
      <div class="container container500">
        <img
          @click="goBack()"
          class="back pointer"
          src="../assets/images/arrow-back.png"
        />
        <p class="text-desc text-bold text-center text-blue white-bold-desktop">
          {{ t("Cadastro") }}
        </p>
        <div class="signin-step-holder">
          {{ `${t("Passo")} ${signUpStep} / 3` }}
        </div>
      </div>
    </header>

    <div v-show="creatingSubscription" class="bg-cover flex-center">
      <div class="desktop12 tablet12 mobile12 flex-center flex-column">
        <div class="loading-spinner"></div>
        <p class="text-desc mt-1 text-white text-center">
          {{ t("Processando pagamento") }}. <br />{{ t("Por favor, aguarde") }}.
        </p>
      </div>
    </div>

    <div v-show="signUpStep == 1" class="page-anim2">
      <div class="container container500">
        <h1 class="title text-blue mt-2">{{ t("Dados pessoais") }}</h1>
        <div
          class="input-holder labeled login first desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">
            {{ t("Nome completo") }} *
          </p>
          <img src="../assets/images/icon-user.png" />
          <input
            v-model="user.name"
            type="text"
            :placeholder="t('Ex:') + ' Maria da Silva'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">
            {{ t("Nome profissional") }} *
          </p>
          <img src="../assets/images/icon-user.png" />
          <input
            v-model="user.professionalName"
            type="text"
            :placeholder="t('Ex:') + ' Dra Maria'"
          />
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">E-mail *</p>
          <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.email"
            type="email"
            :placeholder="t('Ex:') + ' ' + t('nome@dominio.com')"
          />
        </div>

        <!-- <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("CPF") }} *</p>
          <img class="icon-cpf" src="../assets/images/icon-cpf.png" />
          <input
            v-model="user.cpf"
            :placeholder="t('Ex:') + ' 123.456.789-10'"
          />
        </div> -->

        <!-- <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">
            {{ t("Data de nascimento") }} *
          </p>
          <img src="../assets/images/icon-birthday.png" />
          <the-mask
            v-model="birthday"
            :placeholder="t('DD/MM/AAAA')"
            :mask="['##/##/####']"
            :masked="true"
          />
        </div> -->

        <div class="flex-between mt-2 desktop12 tablet12 mobile12">
          <div class="input-holder labeled tablet3">
            <p class="text-desc text-brown text-bold">
              {{ t("Código País") }} *
            </p>
            <img src="../assets/images/icon-phone.png" />
            <the-mask v-model="userCallingCode" :mask="['+##']" />
          </div>

          <div class="input-holder labeled desktop9 tablet8 mobile7">
            <p class="text-desc text-brown text-bold">{{ t("Número") }} *</p>
            <input v-model="userPhoneNumber" style="padding-left: 0" />
          </div>
        </div>

        <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
          <p class="text-desc text-brown text-bold">{{ t("Gênero") }} *</p>
          <img src="../assets/images/icon-user.png" />
          <select v-model="user.genre">
            <option value>{{ t("Selecione uma opção") }}</option>
            <option value="female">{{ t("Feminino") }}</option>
            <option value="male">{{ t("Masculino") }}</option>
          </select>
        </div>

        <transition enter-active-class="animated fadeIn">
          <div
            v-show="user.genre"
            class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
          >
            <p class="text-desc text-brown text-bold">
              {{ t("Nível de Carreira") }} *
            </p>
            <img src="../assets/images/icon-user.png" />
            <select v-model="user.career">
              <option value>{{ t("Selecione uma opção") }}</option>
              <option
                v-for="(career, index) in careers"
                :key="index"
                :value="career"
              >
                {{ career[user.genre] }}
              </option>
            </select>
          </div>
        </transition>

        <div
          v-show="!renewal"
          class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">{{ t("Senha") }} *</p>
          <img class="icon-address" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.password"
            type="password"
            placeholder="*******"
          />
        </div>

        <div
          v-show="!renewal"
          class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
        >
          <p class="text-desc text-brown text-bold">
            {{ t("Confirmar Senha") }} *
          </p>
          <img class="icon-address" src="../assets/images/icon-pwd.png" />
          <input
            v-model="user.password2"
            type="password"
            placeholder="*******"
          />
        </div>

        <p class="text-desc terms mt-2">
          {{ t("Ao continuar você confirma que") }} <br />{{
            t("leu e está de acordo com os nossos")
          }}
          <a
            target="_blank"
            href="https://cliente.appdoit.com.br/documents/DoIt-TermosDeUso.pdf"
            >{{ t("termos de uso") }}</a
          >.
        </p>

        <transition enter-active-class="animated fadeIn">
          <p
            v-show="warningMessage != ''"
            class="text-desc text-center text-blue mt-2"
          >
            {{ warningMessage }}
          </p>
        </transition>

        <button @click="nextStep()" class="default bg-brown my-2">
          {{ t("Avançar") }}
        </button>
      </div>
    </div>

    <transition enter-active-class="page-anim2">
      <div class="pb-2-desktop" v-show="signUpStep == 2">
        <div class="container flex-between-desktop">
          <h1 class="title text-blue mt-2 desktop12">{{ t("Planos") }}</h1>
          <div
            v-for="plan in plans"
            :key="plan.internal_id"
            class="plan-card mt-1 pointer desktop12 tablet12 mobile12"
            :class="{
              'shadow-default': selectedPlan.internal_id == plan.internal_id,
            }"
            @click="selectedPlan = plan"
          >
            <div class="plan-header flex-between">
              <p class="text-desc text-white text-bold">{{ plan.name }}</p>
              <p class="text-desc text-brown-light text-bold">
                {{ currency(plan.value) }} / {{ t("mês") }}
              </p>
            </div>
            <p class="text-desc text-light description text-brown">
              {{ t(plan.description) }}
            </p>
            <p class="text-desc text-light text-blue text-bold type">
              {{ t("Assinatura mensal recorrente") }}
            </p>
          </div>

          <div class="container">
            <p
              class="
                text-desc text-light text-blue text-bold
                type
                mt-2
                text-center
              "
              style="margin: initial"
            >
              {{ t("Você poderá cancelar seu plano a qualquer momento.") }}
            </p>
          </div>
          <div class="container coupon desktop4">
            <div class="input-holder coupon my-2">
              <img src="../assets/images/icon-coupon.png" />
              <input
                v-model="couponCode"
                type="text"
                :placeholder="t('Cupom de desconto')"
              />
            </div>
            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc text-blue text-center mt-2"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button @click="pickPlan()" class="default bg-brown mt-2 mb-2">
              <span v-show="!loading">
                {{
                  selectedPlan.internal_id && couponCode && validCoupon === ""
                    ? t("Validar Cupom")
                    : t("Avançar")
                }}
              </span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </div>
    </transition>

    <transition enter-active-class="page-anim2">
      <div v-show="signUpStep == 3">
        <div class="container container500" v-show="paymentMethod == 0">
          <h1 class="title text-blue mt-2">{{ t("Dados de Pagamento") }}</h1>
          <div class="container container500 pb-55-mobile">
            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Número do cartão") }} *
              </p>
              <img src="../assets/images/icon-card.png" />
              <the-mask
                v-model="creditCard.number"
                :mask="['#### #### #### ####']"
                :masked="false"
              />
            </div>
            <div class="flex-between">
              <div class="input-holder half labeled mt-2 page-anim1">
                <p class="text-desc text-brown text-bold">
                  {{ t("Vencimento") }} *
                </p>
                <select v-model="creditCard.month" style="padding-left: 0">
                  <option value="">{{ t("Mês") }}</option>
                  <option
                    v-for="month in [
                      '01',
                      '02',
                      '03',
                      '04',
                      '05',
                      '06',
                      '07',
                      '08',
                      '09',
                      '10',
                      '11',
                      '12',
                    ]"
                    :key="month"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class="input-holder half labeled mt-2 page-anim1">
                <select v-model="creditCard.year" style="padding-left: 0">
                  <option value="">{{ t("Ano") }}</option>
                  <option
                    v-for="year in creditCardYears"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="
                input-holder
                labeled
                login
                first
                desktop12
                tablet12
                mobile12
              "
            >
              <p class="text-desc text-brown text-bold">
                {{ t("Código de segurança") }} *
              </p>
              <img src="../assets/images/icon-lock.png" />
              <input
                v-model="creditCard.securityCode"
                type="number"
                :placeholder="t('Ex:') + ' 123'"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <div
                v-show="creditCard.name && creditCard.number"
                class="creditcard my-2"
              >
                <img class="chip" src="../assets/images/icon-cpu.png" alt />
                <p class="subtitle number text-white">
                  {{ creditCard.number }}
                </p>
                <p
                  style="text-transform: uppercase"
                  class="subtitle name text-white"
                >
                  {{ creditCard.name }}
                </p>
                <div class="dates flex-start">
                  <p class="text-desc text-white">
                    {{ t("Validade") }}:
                    <b>{{
                      ` ${this.creditCard.month}/${this.creditCard.year}`
                    }}</b>
                  </p>
                </div>
                <img
                  v-show="creditCardFlagImage"
                  :src="creditCardFlagImage"
                  class="flag"
                />
                <p class="text-desc security-code text-white">
                  {{ creditCard.securityCode }}
                </p>
              </div>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <div>
                <div
                  class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("País") }} *
                  </p>
                  <img
                    class="icon-address"
                    src="../assets/images/icon-address.png"
                  />
                  <select v-model="selectedCountry" style="padding-left: 30px">
                    <option
                      v-for="(country, index) in countries"
                      :key="index"
                      :value="country"
                    >
                      {{ country.nativeName }}
                    </option>
                  </select>
                </div>
                <div
                  class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Estado ou Província") }} *
                  </p>
                  <img
                    class="icon-address"
                    src="../assets/images/icon-address.png"
                  />
                  <input v-model="paymentData.state" type="text" />
                </div>
                <div
                  class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Cidade") }} *
                  </p>
                  <img
                    class="icon-address"
                    src="../assets/images/icon-address.png"
                  />
                  <input v-model="paymentData.city" type="text" />
                </div>
                <div
                  class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Endereço") }} *
                  </p>
                  <img
                    class="icon-address"
                    src="../assets/images/icon-address.png"
                  />
                  <input
                    v-model="paymentData.address"
                    type="text"
                    :placeholder="
                      t('Ex:') + ' ' + t('Rua') + ' Washington, 123, SP'
                    "
                  />
                </div>
                <div
                  class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
                >
                  <p class="text-desc text-brown text-bold">{{ t("CEP") }} *</p>
                  <img
                    class="icon-address"
                    src="../assets/images/icon-address.png"
                  />
                  <input
                    v-model="paymentData.zipcode"
                    :placeholder="t('Ex:') + ' 12345678'"
                  />
                </div>
                <div>
                  <p
                    class="
                      text-desc text-light text-blue text-bold
                      type
                      mt-2
                      text-center
                    "
                    style="margin: initial"
                  >
                    *{{
                      t(
                        "a conversão exata para a sua moeda será feita pelo seu banco no momento do pagamento."
                      )
                    }}
                  </p>
                </div>
              </div>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc text-blue text-center mt-2"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button @click="signUp()" class="default bg-brown mt-2 mb-2">
              <span v-show="!loading">{{ t("Cadastrar") }}</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import validators from "../util/validators";
import Countries from "@/assets/countries.json";

export default {
  name: "CadastroInternacional",
  data() {
    return {
      countries: [],
      selectedCountry: null,
      creditCardYears: [],
      creatingSubscription: false,
      warningMessage: "",
      loading: false,
      genre: "female",
      careers: [],
      renewal: false,
      userCallingCode: "",
      userPhoneNumber: "",
      user: {
        name: "",
        email: "",
        image: "",
        cpf: "",
        callingCode: "",
        phone: "",
        address: "",
        number: "",
        neighborhood: "",
        zipcode: "",
        city: "",
        state: "",
        professionalName: "",
        consultantCode: "",
        principalName: "",
        genre: "",
        career: "",
        password: "",
        password2: "",
        birthday: new Date(),
      },
      useUserData: true,
      creditCard: {
        number: "",
        name: "",
        month: "",
        year: "",
        securityCode: "",
        flag: "",
      },
      paymentData: {
        country: "",
        state: "",
        city: "",
        address: "",
        zipcode: "",
      },
      creditCardFlagImage: "",
      signUpStep: 3,
      paymentMethod: "",
      plans: [],
      selectedPlan: {
        internal_id: null,
        name: "",
        value: 0,
      },
      couponCode: "",
      consulta: {},
      validCoupon: "",
      language: "",
    };
  },
  created() {
    this.language = localStorage.language || "br";
    this.$translate.setLang(this.language);

    this.countries = Countries;
    this.selectedCountry = JSON.parse(localStorage.selectedCountry);

    var currentYear = new Date().getFullYear();
    for (let i = currentYear; i < currentYear + 10; i++) {
      this.creditCardYears.push(i.toString());
    }

    http
      .get("plan?paymentMethod=Stripe")
      .then((response) => {
        this.plans = response.data;
      })
      .catch((err) => {
        console.error(err);
      });

    http
      .get("career?take=-1&language=Spanish")
      .then((response) => {
        this.careers = response.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.$route.query.renew === "y") {
      http
        .get("user/details", authentication.getHeaders())
        .then((response) => {
          this.renewal = true;
          this.user = response.data;
          this.nextStep();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) this.$router.push("login");
        });
    }
  },
  methods: {
    currency(value) {
      return "$" + value.toFixed(2);
    },
    nextStep() {
      this.user.name = this.user.name.trim();
      if (!validators.validateFullName(this.user.name)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nome inteiro"];
        return;
      }
      if (this.user.professionalName == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nome profissional"];
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um e-mail válido"];
        return;
      }
      if (this.userPhoneNumber == "" || this.userCallingCode == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe um número de telefone ou celular válido"
          ];
        return;
      }
      if (this.user.genre == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu gênero"];
        return;
      }
      if (this.user.career == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nível de carreira"];
        return;
      }
      if (this.user.password == "" && !this.renewal) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe sua senha"];
        return;
      }
      if (this.user.password2 == "" && !this.renewal) {
        this.warningMessage =
          this.$translate.locale["Por favor, confirme a sua senha"];
        return;
      }
      if (this.user.password !== this.user.password2 && !this.renewal) {
        this.warningMessage =
          this.$translate.locale[
            "Suas senhas são diferentes. Por favor, digite-as novamente"
          ];
        return;
      }
      this.warningMessage = "";
      this.signUpStep = 2;
    },
    pickPlan() {
      if (!this.selectedPlan.internal_id) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, selecione um plano para continuar"
          ];
        return;
      }

      if (this.couponCode && this.couponCode != this.validCoupon) {
        this.loading = true;
        http
          .get(`coupon/validate/stripe/${this.couponCode}`)
          .then((response) => {
            var coupon = response.data;
            if (coupon?.valid) {
              this.warningMessage = this.couponCode + " aplicado com sucesso!";
              this.validCoupon = this.couponCode;
            } else {
              this.warningMessage = "Cupom inválido ou expirado";
              this.couponCode = "";
            }
          })
          .catch((err) => {
            console.log(err);
            this.warningMessage =
              this.$translate.locale[
                "Algo deu errado. Por favor, tente novamente em alguns instantes."
              ];
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.warningMessage = "";
        this.signUpStep = 3;
        this.setUserDataForCard();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    signUp() {
      if (this.creditCard.number == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o número do dono do cartão"
          ];
        return;
      }

      if (this.creditCard.month == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o mês de validade do cartão"
          ];
        return;
      }

      if (this.creditCard.year.length !== 4) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o ano de validade do cartão no formato AAAA"
          ];
        return;
      }

      if (this.creditCard.securityCode == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o código de segurança do cartão"
          ];
        return;
      }

      if (this.paymentData.zipcode == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu cep"];
        return;
      }
      if (this.paymentData.state == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um UF válido"];
        return;
      }
      if (this.paymentData.city == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe a cidade"];
        return;
      }
      if (this.paymentData.address == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu endereço"];
        return;
      }

      if (this.userCallingCode) {
        this.user.phone = this.userCallingCode + this.userPhoneNumber;
      }

      this.warningMessage = "";
      this.loading = true;

      if (this.renewal) {
        this.createSubscriptionByCreditCard(this.user);
      } else {
        http
          .post("user", this.user)
          .then((response) => {
            localStorage.user = JSON.stringify(response.data);
            this.$swal
              .fire({
                type: "success",
                title: this.$translate.locale["Cadastro realizado com sucesso"],
              })
              .then((res) => {
                if (res) {
                  this.createSubscriptionByCreditCard(response.data.info);
                }
              });
          })
          .catch((err) => {
            this.warningMessage = err.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createSubscriptionByCreditCard(user) {
      var vm = this;
      var subscriptionInfo = {
        paymentMethod: "Stripe",
        PlanId: vm.selectedPlan.internal_id,
        Name: vm.selectedPlan.name,
        Coupon: {
          code: vm.validCoupon,
        },
        creditCard: {
          number: this.creditCard.number,
          expMonth: this.creditCard.month,
          expYear: this.creditCard.year,
          cvc: this.creditCard.securityCode,
        },
      };

      var userPaymentData = user;
      userPaymentData.country = this.selectedCountry.alpha2Code;
      userPaymentData.state = this.paymentData.state;
      userPaymentData.city = this.paymentData.city;
      userPaymentData.address = this.paymentData.address;
      userPaymentData.zipcode = this.paymentData.zipcode;

      var subscriptionForm = {
        subscription: subscriptionInfo,
        user: userPaymentData,
      };

      vm.creatingSubscription = true;
      http
        .post("Subscription", subscriptionForm, authentication.getHeaders())
        .then(() => {
          vm.$swal
            .fire({
              type: "success",
              title: this.$translate.locale["Recebemos sua transação"],
              text: this.$translate.locale[
                "A confirmação do pagamento pode levar até 48 horas"
              ],
            })
            .then((res) => {
              if (res) {
                vm.$router.push("/home?newUser=true");
              }
            });
        })
        .catch((err) => {
          console.log(err);
          vm.$swal
            .fire({
              type: "error",
              title: this.$translate.locale["Algo deu errado"],
              text: this.$translate.locale[
                "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente"
              ],
            })
            .then((res) => {
              if (res) {
                localStorage.paymentFailed = true;
                vm.$router.push("/home?newUser=true");
              }
            });
        })
        .finally(() => {
          vm.creatingSubscription = false;
          vm.loading = false;
        });
    },
    goBack() {
      if (
        (this.signUpStep > 1 && !this.renewal) ||
        (this.signUpStep > 2 && this.renewal)
      ) {
        this.signUpStep -= 1;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped>
p.terms {
  position: relative;
  text-align: center;
}

p.terms a {
  font-size: 1em;
  color: #0f2651;
  font-weight: bold;
}

p.text-desc.type {
  margin: 5px 0 0 15px;
}

.input-holder.half {
  width: 48% !important;
}

.payment-text {
  font-size: 16px;
}

.order-summary-title {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
}

.order-summary-value {
  font-size: 14px;
  margin-top: 4px;
}
</style>
